import api from '@/plugins/axios';
import { AI_INTEGRATION, AiIntegrationConfig } from '@/types';

export const GetConfiguration = async (id: AI_INTEGRATION) => {
    const { data } = await api.get<AiIntegrationConfig>(`/ai/${id}`);
    return data
}

export const UpdateConfiguration = async (id: AI_INTEGRATION, config: AiIntegrationConfig) => {
    await api.put(`/ai/${id}`, config);
}