

import { Component, Vue, Watch } from "vue-property-decorator";
import * as AiServices from '@/services/AiServices';
import { AI_INTEGRATION, AiIntegrationConfig } from "@/types";
import { ROOT_ACTIONS } from "@/store/actions";

@Component
export default class AiIndex extends Vue {
    loading = false;

    tab = 0;

    data = {

    } as AiIntegrationConfig

    async mounted() {
        await this.getData(AI_INTEGRATION.SEO_CONFIG);
    }

    async getData(item: AI_INTEGRATION) {
        let data = await AiServices.GetConfiguration(item);
        this.data = data;
        this.loading = false;
        this.$forceUpdate();
    }

    @Watch('tab')
    async onTabChanged() {
        if (this.tab === 0) {
            await this.getData(AI_INTEGRATION.SEO_CONFIG);
        } else if (this.tab === 1) {
            await this.getData(AI_INTEGRATION.TWEET_SENTIMENT);
        }
    }

    async submit() {
        try {
            this.loading = true;
            let integration: AI_INTEGRATION;
            if (this.tab === 0) {
                integration = AI_INTEGRATION.SEO_CONFIG;
            } else {
                integration = AI_INTEGRATION.TWEET_SENTIMENT;
            }

            await AiServices.UpdateConfiguration(integration, this.data);
            this.loading = false;
            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                color: 'success',
                text: 'Opgeslagen'
            })
        } catch (error) {
            console.log(error);
        }
    }
}
